<template>
  <SprintAnswerBackground
    @backTraining="backTraining"
    :progresses="engTrainingProgresses"
    :items="getTableItems()"
    @seeAnswer="seeAnswer"
    :questionInfos="questionInfos.simulationExamQuestions"
    :isRedo="isRedo"
    id="printer"
  >
    <b-row class="answer-title">
      <b-col cols="8" class="text-left">
        <span>{{ $route.query.name || logDetail.questionClass }}</span>
        <span class="answer-hint" v-if="$route.query.type == 24"
          >（请阅读源语言，翻译成对应的英文或者中文）</span
        >
        <span class="answer-capital" v-if="$route.query.type == 7"
          >（请输入大写字母）</span
        >
      </b-col>
      <b-col>
        <GlobalAnswerStates
          v-if="!showLog"
          ref="group"
          :groupAnswerStates="groupAnswerStates"
        >
        </GlobalAnswerStates>
      </b-col>

      <GlobalButton
        v-if="showLog"
        buttonType="yellow"
        buttonText="返回记录页"
        @afterClick="$router.go(-1)"
      ></GlobalButton>
      <GlbalTopicPopover
        v-if="questionInfos.stemStatement"
        :stemStatement="questionInfos.stemStatement"
      >
      </GlbalTopicPopover>
      <GlbalTopicPopover
        v-else
        v-for="(item, index) in questionInfos.simulationExamQuestions"
        :key="index"
        :stemStatement="item.statement"
      >
      </GlbalTopicPopover>
    </b-row>
    <b-row style="min-height: 25rem">
      <b-col>
        <div style="align-items: flex-start; display: flex">
          <span v-if="!showLog" class="question-stem-number">{{
            questionInfos.questionNumber || questionNumber
          }}</span>
          <EnglishtestTrainingQuestions
            :section="getSection()"
            :examQuestion="questionInfos"
            @changeRadio="changeRadio"
            :errAnswers="errAnswers"
            @getInput="getInput"
            :isSelfScores="isSelfScores"
            @textareaChange="textareaChange"
            @openSelfScore="openSelfScore"
            @afterRefreshImg="afterRefreshImg"
            :selfEvaluation="selfEvaluation"
            :totalPrice="totalPrice"
            :inputErrs="inputErrs"
            :answerCount="answerCount"
            :isAnswerKeyOpen="isAnswerKeyOpen"
            :isRedo="isRedo"
          />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!showLog" class="submit-group">
      <b-col class="text-right">
        <GlobalButton
          buttonType="round"
          buttonText="提交答案"
          @afterClick="checkSubmit"
          :disabled="canNext || submiting"
        >
          <b-spinner v-if="submiting" slot="loading" small></b-spinner>
        </GlobalButton>
        <GlobalButton
          buttonType="round-text"
          @afterClick="nextQuestion"
          buttonText="下一题"
          :disabled="!canNext"
        ></GlobalButton>
      </b-col>
    </b-row>
    <PersonalNotes v-if="showLog"></PersonalNotes>
    <b-col
      v-if="
        $route.query.type == 6 ||
          $route.query.type == 7 ||
          $route.query.type == 17 ||
          $route.query.type == 18 ||
          $route.query.type == 24 ||
          logDetail.questionClassId == 6 ||
          logDetail.questionClassId == 7 ||
          logDetail.questionClassId == 17 ||
          logDetail.questionClassId == 18 ||
          logDetail.questionClassId == 24
      "
      class="answer-analysis-text"
      cols="12"
    >
      <!-- v-show="answerAnalysiShow($route.query.type)" -->
      <div v-if="isAnswerKeyOpen">
        <b-row
          v-for="question in questionInfos.simulationExamQuestions"
          :key="question.questionId"
          align-h="between"
          class="answer-analysis"
        >
          <b-col align-self="start" cols="6" style="color: #2cb774"
            >答题解析及素养提升：</b-col
          >
          <b-col
            @click="isAnswerKeyClass = !isAnswerKeyClass"
            align-self="start"
            cols="2"
            class="view-all"
            >{{ isAnswerKeyClass ? "收起信息" : "查看全部" }}
            <span
              :class="[
                'iconfont',
                isAnswerKeyClass ? 'icon-jiantou_xia' : 'icon-jiantou8',
              ]"
            ></span>
          </b-col>
          <b-col
            cols="12"
            class="analysis-class"
            :class="isAnswerKeyClass ? 'isAnswerKey-class' : ''"
          >
            <b-row class="reference-answer"
              >正确答案：
              <span v-html="question.standardAnswerKey"></span>
            </b-row>
            <b-row v-html="replaceAnalysis(question.answerAnalysis)"></b-row>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <GlobalPopup
      v-if="!showLog"
      :popupsTitle="cancleTestTitle"
      :popupsbuttomA="cancleTestbuttomA"
      :popupsbuttomB="cancleTestbuttomB"
      @OKbutton="okSelfScore"
      @NObutton="
        () => {
          modal1 = false;
        }
      "
      :isShow="modal1"
    ></GlobalPopup>
    <GlobalPopup
      :popupsTitle="cancleTestTitle"
      :popupsbuttomA="cancleTestbuttomA"
      :popupsbuttomB="cancleTestbuttomB"
      @NObutton="submitAnswer"
      @OKbutton="
        () => {
          modal2 = false;
        }
      "
      :isShow="modal2"
    ></GlobalPopup>
    <GlobalPopup
      :popupsTitle="`确定提交自评分`"
      :popupsbuttomA="`暂不提交`"
      :popupsbuttomB="`确定提交`"
      @OKbutton="svaluationTest"
      @NObutton="
        () => {
          modal5 = false;
        }
      "
      :isShow="modal5"
    ></GlobalPopup>
    <GlobalScoreModal
      :isShowEvaluation="isShowEvaluation"
      :engSelfQuestions="engSelfQuestions"
      :isShowTable="isShowTable"
      :resultModelEssay="resultModelEssay"
      :totalPrice="totalPrice"
      @submitRating="submitRating"
      @modelEssay="modelEssay"
      @changeArticle="changeArticle"
    >
    </GlobalScoreModal>
  </SprintAnswerBackground>
</template>

<script>
import SprintAnswerBackground from "@/components/backgroung/sprint-answer-background.vue";
import EnglishtestTrainingQuestions from "@/components/englinsh-test/english-test-training-questions.vue";
import PersonalNotes from "@/components/personal_notes/personal_notes";
// import SelectQuestion from "@/views/english-mock/components/SelectQuestion.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import GlobalAnswerStates from "../../components/global-popup/global-answer-states";
import GlbalTopicPopover from "../../components/global-popup/glbal-topic-popover.vue";
import GlobalScoreModal from "@/components/global-popup/global-score-modal.vue";
import { mapState, mapMutations } from "vuex";
import {
  getBigModuleQuestions,
  saveManyTrainingAnswers,
} from "@/api/english-big";
import { SaveRedoAnswer } from "@/api/english-entry";
import {
  getEngSelfQuestion,
  saveEngSelfQuestion,
} from "@/api/english-examination";
export default {
  name: "big",
  props: {
    isRedo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionInfos: {},
      engTrainingProgresses: [],
      result: "",
      studentAnswers: [],
      trainingQuestionAnswers: [],
      canNext: false,
      answerCount: 0,
      groupAnswerStates: [],
      errAnswers: "",
      isSecondAnswer: false,
      isSelfScores: [true, true, true, true, true, true, true, true, true],
      selfScoreIndex: "",
      selfScoreType: "",
      isSubmitPic: false,
      cancleTestTitle: "你正在离开答题区，离开后下次进入将重新开始测试！",
      cancleTestbuttomA: "",
      cancleTestbuttomB: "继续测试",
      modal1: false,
      modal2: false,
      modal5: false,
      leaveIllegally: false,
      submiting: false,
      inputErrs: "",
      items: [],
      showTip: false,
      tipTitle: "",
      imgs: {
        1: require("@/assets/images/icon-zhengque@2x.png"),
        3: require("@/assets/images/icon-cuowu@2x.png"),
        0: require("@/assets/images/icon-kongbai@2x.png"),
      },
      isAnswerKeyClass: false,
      isAnswerKeyOpen: false,
      isShowEvaluation: false,
      compositionQuestionId: "",
      engSelfQuestions: [],
      resultModelEssay: "",
      isShowTable: false,
      totalPrice: 0,
      selfEvaluation: false,
      isSeeAnswer: false,
      isAnswerShow: false,
      isSelfScoreShow: false,
    };
  },
  components: {
    SprintAnswerBackground,
    GlobalButton,
    EnglishtestTrainingQuestions,
    GlobalPopup,
    PersonalNotes,
    GlobalAnswerStates,
    GlbalTopicPopover,
    GlobalScoreModal,
  },
  computed: {
    ...mapState([
      "trainingData",
      "userAnswers",
      "testEndTime",
      "trainingTest",
      "userCorrectAnswer",
      "values",
      "trainingBeginTime",
      "trainingAnswer",
      "currentModuleId",
      "logDetail",
    ]),
    ...mapState({ questionNumber: (state) => state.questionNumber }),
    showLog() {
      return this.$route.meta.showLog;
    },
  },
  created() {
    this.init();
  },
  activated() {
    this.questionInfos = [];
    this.init();
  },
  mounted() {
    if (sessionStorage.getItem("values")) {
      this.getOldInputAnswer();
    }
    if (this.userAnswers) {
      this.trainingQuestionAnswers = this.userAnswers;
      this.getOldwritingAnswers(1);
    }
  },
  methods: {
    seeAnswer(isShow) {
      this.isSeeAnswer = isShow;
    },
    answerAnalysiShow(type) {
      if (this.showLog) {
        return true;
      }
      if (type == 6 || type == 7 || type == 17 || type == 18) {
        if (
          this.answerCount == 2 || this.trainingAnswer !== null
            ? this.trainingAnswer.isCorrect
            : ""
        ) {
          return true;
        }
      } else {
        if (this.answerCount == 1) {
          return true;
        }
      }
    },
    checkSubmit() {
      if (
        this.$route.query.type == 6 ||
        this.$route.query.type == 7 ||
        this.$route.query.type == 17 ||
        this.$route.query.type == 18
      ) {
        if (!sessionStorage.getItem("values")) {
          this.openModal2();
        } else {
          this.submitAnswer();
          return;
        }
      } else {
        if (this.$route.query.type == 19 || this.$route.query.type == 24) {
          if (!this.checkedSelfAnswer()) {
            this.openModal2();
          } else {
            this.submitAnswer();
            return;
          }
        } else {
          if (!this.checkUserAnswer()) {
            this.openModal2();
          } else {
            this.submitAnswer();
            return;
          }
        }
      }
    },
    checkedSelfAnswer() {
      let answerArray = [];
      let selfScoreArray = [];
      console.log("this.userAnswers", this.userAnswers);
      for (let a of this.userAnswers) {
        answerArray.push(a.answer ? true : false);
        selfScoreArray.push(a.selfScore || a.selfScore === 0 ? true : false);
      }
      this.isAnswerShow = answerArray.every((item) => {
        return item === true;
      });
      this.isSelfScoreShow = selfScoreArray.every((item) => {
        return item === true;
      });
      console.log("答案数组", answerArray);
      console.log("自评数组", selfScoreArray);
      console.log("是否全部答题", this.isAnswerShow);
      console.log("是否全部自评", this.isSelfScoreShow);

      if (this.isAnswerShow && this.isSelfScoreShow) {
        return true;
      }
    },
    checkUserAnswer() {
      let isAnswer = false;
      for (let a of this.userAnswers) {
        if (a.answer || a.selfScore) {
          isAnswer = true;
          break;
        }
      }
      return isAnswer;
    },
    openModal2() {
      console.log("this.isSelfScoreShow", this.isSelfScoreShow);
      if (this.$route.query.type == 19 || this.$route.query.type == 24) {
        this.cancleTestTitle = !this.isAnswerShow
          ? "你有题目未输入的答案，请全部输入答案后再提交!"
          : !this.isSelfScoreShow
          ? "你有题目还未自评分，请全部自评后再提交!"
          : "你未输入任何答案，确定提交？";
        this.cancleTestbuttomA =
          !this.isAnswerShow || !this.isSelfScoreShow ? "" : "确认提交";
      } else {
        this.cancleTestTitle = "你未输入任何答案，确定提交？";
        this.cancleTestbuttomA = "确认提交";
      }

      this.cancleTestbuttomB = "再想想";
      this.modal2 = true;
    },
    init() {
      if (this.isRedo || this.showLog) {
        if (this.showLog) {
          this.isAnswerKeyOpen = true;
        }
        // if (this.$route.query.type == 24) {
        //   this.isAnswerKeyOpen = false;
        // }
        // const { questionClass: type,difficulty: diffecter, questions } = this.$parent.detail
        this.groupAnswerStates = [{ colorState: 0 }];
        // const questionScore = questions.reduce((a, b) => (a.questionScore + b.questionScore), { questionScore: 0 } )
        // debugger
        // this.items = [ { score: questionScore, type, diffecter}]
        this.engTrainingProgresses = this.listOfWrongQuestions.map(
          (item, i) => ({ colorState: 0, questionNumber: i + 1 })
        );
        return this.resetData();
      }

      if (!sessionStorage.getItem("trainingTest")) {
        this.getQuestion();
      } else {
        this.questionInfos = this.trainingTest.questionInfos;
        this.engTrainingProgresses = this.trainingTest.answerTrainingProgresses;
        this.groupAnswerStates = this.trainingTest.groupAnswerStates;
        if (this.trainingTest.inputErrs) {
          this.inputErrs = this.trainingTest.inputErrs;
          this.errAnswers = this.trainingTest.errAnswers;
        }
      }
      if (sessionStorage.getItem("answerCount")) {
        this.answerCount = sessionStorage.getItem("answerCount");
      }
      if (sessionStorage.getItem("isSelfScores")) {
        this.isSelfScores = JSON.parse(sessionStorage.getItem("isSelfScores"));
      }
      if (this.answerCount == 1) {
        this.canNext = true;
        this.isSecondAnswer = false;
      }
    },
    ...mapMutations([
      "setUserAnswers",
      "setUserCorrectAnswer",
      "setTestEndTime",
      "setTrainingTest",
      "setTrainingData",
      "setTrainingBeginTime",
      "setTrainingAnswer",
    ]),

    openSelfScore(index, type, questionId) {
      this.selfScoreIndex = index;
      this.selfScoreType = type;
      this.compositionQuestionId = questionId;

      this.cancleTestbuttomA = "再想想";
      this.cancleTestbuttomB = !this.userAnswers[index].answer
        ? ""
        : "现在自评";
      this.cancleTestTitle = !this.userAnswers[index].answer
        ? "当前题目未输入答案，请输入答案后再自评！"
        : "现在自评分吗？确定后将打开本题标准答案，供你作评分参考，但不能再修改答案！";
      this.modal1 = true;
    },
    modelEssay() {
      this.isShowTable = !this.isShowTable;
    },
    submitRating() {
      this.modal5 = true;
    },
    afterRefreshImg() {
      this.isSubmitPic = true;
    },
    svaluationTest() {
      saveEngSelfQuestion(this.scoreAnswer).then((res) => {
        console.log("提交自评分", res);
        this.selfEvaluation = res.data.result;
        this.modal5 = false;
        this.modal1 = false;
        this.isShowEvaluation = false;
        // this.setSelfScore();
      });
    },
    // setSelfScore() {
    //   let answers = this.userAnswers;
    //   for (let a of answers.answerSectionInputs) {
    //     if (a.sectionType === 8) {
    //       a["answerQuestionInputs"][a["answerQuestionInputs"].length - 1][
    //         "selfScore"
    //       ] = this.totalPrice;
    //     }
    //   }
    //   this.setUserAnswers(answers);
    // },
    changeArticle(event, index) {
      console.log("event, index", event, index);
      this.scoreAnswer.selfAnswerInput[index].answerKey = event;
      let arr = this.engSelfQuestions[index].options;
      arr.find((obj) => {
        if (event == obj.value) {
          this.scoreAnswer.selfAnswerInput[index].selfScore = obj.score;
        }
      });
      this.sum(this.scoreAnswer.selfAnswerInput);
    },
    sum(arr) {
      console.log(arr);
      // this.totalPrice = arr.reduce((p, e) => p + e.selfScore, 0);
      var strarr = [];
      for (let i in arr) {
        strarr.push(arr[i].selfScore);
      }
      this.totalPrice = eval(strarr.join("+"));
    },
    okSelfScore() {
      debugger;
      this.modal1 = false;
      this.selfEvaluation = true;
      if (this.selfScoreType === 20 && !this.isSubmitPic) {
        return;
      }
      if (this.selfScoreType === 20) {
        this.writeSelfScore = false;
        sessionStorage.setItem(
          "writeSelfScore",
          JSON.stringify(this.writeSelfScore)
        );
        this.isShowEvaluation = true;
        const parmeter = {
          questionId: this.compositionQuestionId,
        };
        getEngSelfQuestion(parmeter).then((res) => {
          console.log("自评分试题", res);
          this.resultModelEssay = res.data.result.modelEssay;
          this.engSelfQuestions = res.data.result.engSelfQuestions;
          this.scoreAnswer = {
            questionId: this.compositionQuestionId,
            selfAnswerInput: [],
          };

          for (const a of this.engSelfQuestions) {
            this.scoreAnswer.selfAnswerInput.push({
              selfQuestionId: a.selfQuestionId, //试题主键
              selfScore: "0", //单个自评分
              answerKey: "", //答案
            });
          }
        });
        return;
      }
      this.$set(this.isSelfScores, this.selfScoreIndex, false);

      sessionStorage.setItem("isSelfScores", JSON.stringify(this.isSelfScores));
    },
    // 情景运用  作文
    textareaChange(value, index, questionId, state, score) {
      console.log("写作内容", value, index, questionId, state, score);
      if (value >= 2 && state === 1) {
        value = 2;
      }
      for (const a of this.trainingQuestionAnswers) {
        if (a.questionId === questionId) {
          if (state === 0) a.answer = value;
          if (state === 1) a.selfScore = Number(value);
          break;
        }
      }
      this.setUserAnswers(this.userAnswers);
      sessionStorage.setItem(
        "textareaAnswers",
        JSON.stringify(this.textareaAnswers)
      );
    },
    // 填空题
    getInput(index, questionId, sectionType) {
      const h = document
        .getElementsByClassName(questionId)[0]
        .getElementsByTagName("input");
      console.log("html答案", index, h);
      const values = Array.prototype.map.call(h, (item) => item.value);
      const sesValue = JSON.parse(sessionStorage.getItem("values"))
        ? JSON.parse(sessionStorage.getItem("values"))
        : {};
      values.push(sectionType);
      sesValue[questionId] = values;
      window.sessionStorage.setItem("values", JSON.stringify(sesValue));
    },
    // 题型
    getTableItems() {
      if (this.isRedo || this.showLog) return this.items;
      return [
        {
          type: this.getQuestionType(this.$route.query.type),
          diffecter: this.getDiffcaultName(this.trainingData.difficultyTagId),
          score: this.questionInfos.sectionScore,
        },
      ];
    },
    resetData() {
      const {
        questionClass: type,
        difficulty: diffecter,
        questions,
        stem,
        questionClassId,
      } = this.logDetail;
      this.$route.query.type = questionClassId;
      const questionScore = questions
        .map((item) => item.questionScore)
        .reduce((a, b) => a + b, 0);
      this.items = [{ score: questionScore, type, diffecter }];
      this.questionInfos.simulationExamQuestions = questions.map(
        (question, i) => {
          if (
            (question.questionClass == 3 && question.variation != 0) ||
            (question.questionClass == 4 && question.variation != 0) ||
            (question.questionClass == 19 && question.variation != 0)
          ) {
            return question;
          } else {
            question.variation = i + 1;
            return question;
          }
        }
      );
      this.questionInfos.plyTkSectionId = questionClassId;
      this.questionInfos.stemStatement = stem;
      this.trainingQuestionAnswers = [];

      // 专门存储questionId，answer
      for (const i in this.questionInfos.simulationExamQuestions) {
        this.trainingQuestionAnswers.push({
          questionId: this.questionInfos.simulationExamQuestions[i].questionId,
          answer: "",
          isCorrect: "",
          selfScore: 0,
          // examId: this.examId
        });
      }
      this.errAnswers = "";
      this.setUserAnswers(this.trainingQuestionAnswers);
    },
    //下一题
    nextQuestion() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.setUserAnswers("");
      this.setTrainingAnswer("");
      this.isAnswerKeyOpen = false;
      if (this.isRedo) {
        this.isSeeAnswer = false;
        sessionStorage.setItem("answerCount", 0);
        this.selfEvaluation = false;
        this.errAnswer = "";
        this.groupAnswerStates = [{ colorState: 0 }];
        return this.$parent.getData().then(() => {
          this.$store.commit("setQuestionNumber", this.questionNumber + 1);
          this.resetData();
          this.canNext = false;
        });
      }
      this.getQuestion();
    },
    // 第一：进页面先调题，把数组加入缓存
    getQuestion() {
      let parmeter = this.trainingData;
      getBigModuleQuestions(parmeter).then((res) => {
        console.log("训练答题", res);
        this.questionInfos = res.data.result.questionInfos;
        this.groupAnswerStates = res.data.result.groupAnswerStates;
        this.engTrainingProgresses = res.data.result.answerTrainingProgresses;
        this.answerCount = 0;
        this.isSecondAnswer = false;
        this.isSeeAnswer = false;
        this.trainingQuestionAnswers = [];
        //获取试题接口的所有数据先存起来
        this.setTrainingTest(res.data.result);
        // 专门存储questionId，answer
        for (const i in this.questionInfos.simulationExamQuestions) {
          this.trainingQuestionAnswers.push({
            questionId: this.questionInfos.simulationExamQuestions[i]
              .questionId,
            isCorrect: this.result.isCorrect,
          });
        }
        this.errAnswers = "";

        this.setUserAnswers(this.trainingQuestionAnswers);
        sessionStorage.setItem("answerCount", this.answerCount);
        this.setTrainingBeginTime(Date.parse(new Date()));
        this.canNext = false;
        this.isSelfScores = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];

        sessionStorage.setItem(
          "isSelfScores",
          JSON.stringify(this.isSelfScores)
        );
        sessionStorage.removeItem("values");
      });
    },

    // 提交答案
    submitAnswer() {
      // 填空题提交逻辑
      this.modal2 = false;
      const obj = JSON.parse(sessionStorage.getItem("values"));
      if (
        this.$route.query.type == 6 ||
        this.$route.query.type == 7 ||
        this.$route.query.type == 17 ||
        this.$route.query.type == 18
      ) {
        if (obj) {
          for (const key in obj) {
            console.log("obj", obj, "key", key);
            var answer = "";

            for (let i = 0; i < obj[key].length - 1; i++) {
              if (i === obj[key].length - 2) {
                answer += obj[key][i];
              } else {
                answer += obj[key][i] + "|";
              }
            }

            this.trainingQuestionAnswers = [];
            this.trainingQuestionAnswers.push({
              questionId: key,
              answer: answer,
            });
          }
        } else {
          for (const i in this.questionInfos.simulationExamQuestions) {
            this.trainingQuestionAnswers = [];
            this.trainingQuestionAnswers.push({
              questionId: this.questionInfos.simulationExamQuestions[i]
                .questionId,
              answer:
                this.$route.query.type == 6 ||
                this.$route.query.type == 17 ||
                this.$route.query.type == 18
                  ? "|||||||||"
                  : "||||",
            });
          }
        }
      }
      if (this.$route.query.type == 19 || this.$route.query.type == 24) {
        this.isSecondAnswer = true;
      }
      if (this.answerCount >= 1) {
        this.isSecondAnswer = true;
      }
      this.submiting = true;
      //答题时间需要修改
      let traningTime =
        (Date.parse(new Date()) - this.trainingBeginTime) / 1000;
      if (traningTime < 0) {
        traningTime = 0;
      }
      if (traningTime > 600) {
        traningTime = 600;
      }
      //闯关记录重做
      if (this.isRedo) {
        this.isAnswerKeyOpen = true;
        let getAddressImg = sessionStorage.getItem("addressImg");
        SaveRedoAnswer(
          this.trainingQuestionAnswers.map((item) => {
            this.$set(item, "IsRedo", true);
            this.$set(item, "examId", this.examId);
            this.$set(item, "isSeeAnswer", this.isSeeAnswer); //学生做题时是否查看答案
            this.$set(item, "answerSecond", traningTime);
            this.$set(item, "SprintId", this.currentModuleId); //当前关卡id
            // selfEvaluation 是否为自评分
            if (
              this.logDetail.questionClassId == 19 ||
              this.logDetail.questionClassId == 20
            ) {
              this.selfEvaluation = true;
            }
            this.$set(item, "IsSelfRating", this.selfEvaluation);
            if (this.logDetail.questionClassId == 20) {
              this.$set(item, "selfScore", this.totalPrice);
              this.$set(item, "answer", getAddressImg);
            }
            return item;
          })
          // questionId: this.redoQuestion.questionId,
          // isSeeAnswer: this.isSecondAnswer,
        )
          .then((res) => {
            this.canNext = true;
            this.submiting = false;
            if (res.data.success) {
              this.setTrainingAnswer(res.data.result[0]);
              // this.listOfWrongQuestions.shift()
              this.$store.dispatch("completeredoQuestion");
              const { isCorrect } = res.data.result[0];
              const colorState = isCorrect ? 1 : 3;
              this.groupAnswerStates = [{ colorState }];

              const index = this.engTrainingProgresses.findIndex(
                (item) => item.colorState === 0
              );
              this.engTrainingProgresses[index].colorState = colorState;
              this.answerCount++;
              sessionStorage.setItem("answerCount", this.answerCount);
              this.$refs.group.point("1", this.answerCount);
              if (!isCorrect) {
                this.errAnswer = this.answer;
              }
            }
            this.setTrainingBeginTime(Date.parse(new Date()));
          })
          .catch(() => {
            this.submiting = false;
          });
        return;
      }
      this.answerAnalysiShow();

      let parmeter = {
        trainingExamId: this.trainingData.trainingExamId,
        summaryId: this.trainingData.summaryId,
        tagId: this.trainingData.tagId,
        setTagId: this.trainingData.setTagId, //题套
        difficultyTagId: this.trainingData.difficultyTagId, //难度
        isSeeAnswer: this.isSeeAnswer, //是否查看答案
        mode: this.getMode(), //C 闯关 X 自由
        isSecondAnswer: false, //是否为二次答题（true 二次答题）
        answerSecond: traningTime, //答题时间（秒）
        trainingQuestionAnswers: this.trainingQuestionAnswers,
      };
      this.submiting = true;
      saveManyTrainingAnswers(parmeter)
        .then((res) => {
          console.log("训练题保存", res);
          this.submiting = false;
          this.result = res.data.result;
          this.setTrainingAnswer(res.data.result);
          if (res.data.result.isSubmit) {
            this.setUserCorrectAnswer(res.data.result.questionIsCorrects);
            this.trainingData["summaryId"] = res.data.result.summaryId;
            this.setTrainingData(this.trainingData);
          }
          this.answerCount++;
          sessionStorage.setItem("answerCount", this.answerCount);
          if (this.$route.query.type === "24") {
            if (res.data.result.questionIsCorrects[0].isCorrect) {
              this.$set(
                this.groupAnswerStates[res.data.result.currentGroupId - 1],
                "colorState",
                1
              );
              this.getErrAnswers(res.data.result.questionIsCorrects);
            } else {
              this.$set(
                this.groupAnswerStates[res.data.result.currentGroupId - 1],
                "colorState",
                3
              );
              this.getErrAnswers(res.data.result.questionIsCorrects);
            }
          } else {
            if (res.data.result.isCorrect) {
              this.$set(
                this.groupAnswerStates[res.data.result.currentGroupId - 1],
                "colorState",
                1
              );
              this.getErrAnswers(res.data.result.questionIsCorrects);
            } else {
              this.$set(
                this.groupAnswerStates[res.data.result.currentGroupId - 1],
                "colorState",
                3
              );
              // this.errAnswer = this.userAnswers;
              this.getErrAnswers(res.data.result.questionIsCorrects);
            }
          }

          this.$refs.group.point(
            res.data.result.currentGroupId,
            this.answerCount
          );
          // if (this.$route.query.type == 19 && this.answerCount == 1) {
          //   this.canNext = true;
          // } else if (this.$route.query.type == 24 && this.answerCount == 1) {
          //   this.canNext = true;
          // } else {
          if (this.answerCount == 1) {
            this.canNext = true;
          }
          // }
          if (
            this.answerCount == 1 ||
            this.trainingAnswer.isCorrect ||
            this.$route.query.type == 24
          ) {
            this.isAnswerKeyOpen = true;
          }
          if (res.data.result.isFinish) {
            if (this.$route.query.mode === "free") {
              this.$router.replace({
                name: "BigModuleTrainingFreeBase",
                query: {
                  // module: 2,
                },
              });
            } else {
              this.$router.replace({
                name: "BigModuleTrainingQuestionsResults",
                query: {
                  // module: 2,
                  summaryId: this.result.summaryId,
                  isAllFinish: this.result.isAllFinish,
                  sectionName: this.$route.query.name,
                },
              });
            }
          }
          this.setTrainingBeginTime(Date.parse(new Date()));
        })
        .catch((err) => {
          console.log(err);
          this.submiting = false;
        });
    },
    //第二： 点击选项，把答案添加缓存，刷新不会变
    changeRadio(checked, questionId) {
      console.log("用户选择答案", checked, "questionId", questionId);

      this.userAnswers.filter((item, index) => {
        if (item.questionId == questionId) {
          this.userAnswers[index].answer = checked;
          this.setUserAnswers(this.userAnswers);
        }
      });
    },
    getMode() {
      if (this.$route.query.mode == "free") {
        return "X";
      }
      return "C";
    },
    backTraining() {
      if (this.$route.query.mode === "free") {
        this.$router.replace({
          name: "BigModuleTrainingFreeBase",
          query: {
            // module: 2,
          },
        });
        return;
      }
      this.$router.replace({
        name: "BigModuleTrainingBase",
        query: {
          // module: 2,
        },
      });
    },
    getSection() {
      if (this.isRedo) {
        let { questionClassId } = this.logDetail;
        if (questionClassId === 27) {
          questionClassId = 19;
        }

        return {
          sectionType: questionClassId,
          sectionId: questionClassId,
        };
      }
      return {
        sectionType: this.trainingData.tagId,
        sectionId: this.trainingData.tagId,
      };
    },
    getErrAnswers(resErrAnswers) {
      console.log("错误答题状态", resErrAnswers);
      let errAnswers = [];
      let inputErrs = [];
      for (let a of this.questionInfos.simulationExamQuestions) {
        for (let b of resErrAnswers) {
          if (a.questionId === b.questionId) {
            inputErrs.push(b.isCorrect);
            if (b.isCorrect) {
              errAnswers.push("");
            } else {
              errAnswers.push(b.lastAnswer);
            }
          }
        }
      }
      // this.$set('errAnswers',errAnswers)
      this.errAnswers = errAnswers;
      this.inputErrs = inputErrs;
      this.trainingTest["errAnswers"] = errAnswers;
      this.trainingTest["inputErrs"] = inputErrs;
      this.setTrainingTest(this.trainingTest);
      console.log("处理后errAnswers", this.errAnswers);
    },
  },

  beforeRouteLeave(to, from, next) {
    // if (!this.leaveIllegally){
    //   if (to.path == "/login") {
    //     return (this.leaveIllegally = true);
    //   } else {
    //     this.cancleTestTitle = "是否确认放弃答题";
    //     this.cancleTestbuttomA = "确定放弃";
    //     this.cancleTestbuttomB = "再想想";
    //     this.modal1 = true;
    //     return this.leaveIllegally;
    //   }
    // }
    sessionStorage.removeItem("trainingData");
    sessionStorage.removeItem("trainingTest");
    sessionStorage.removeItem("testEndTime");
    sessionStorage.removeItem("userAnswers");
    sessionStorage.removeItem("userCorrectAnswer");
    sessionStorage.removeItem("isSelfScores");
    sessionStorage.removeItem("values");
    sessionStorage.removeItem("textareaAnswers");
    sessionStorage.removeItem("answerCount");
    sessionStorage.removeItem("trainingBeginTime");
    sessionStorage.removeItem("logDetail");
    sessionStorage.removeItem("addressImg");
    this.setTrainingAnswer("");
    next();
  },
};
</script>

<style scoped lang="scss">
.question-stem-number {
  background: #2cb774;
  border-radius: 5px;
  color: #fff;
  padding: 0.15rem 0.6rem;
  margin-top: 15px;
}
.view-all {
  color: #2cb774;
  cursor: pointer;
}
.answer-analysis-text {
  flex: none;
}
.answer-analysis {
  min-height: 9rem;
  text-align: left;
  padding: 1rem 0.5rem;
  border-top: 1px solid rgb(240, 240, 240);
}
.analysis-class {
  padding-top: 2rem;
  height: 6.5rem;
  overflow: hidden;
  h3 {
    font-size: 1rem !important;
  }
  .row {
    margin: 0;
  }
  .reference-answer {
    padding-bottom: 1rem;
    p {
      margin-bottom: 0 !important;
    }
  }
}
.isAnswerKey-class {
  height: 100%;
}
.white-color {
  width: 25px;
  height: 25px;
}
.text-left {
  text-align: left;
  font-weight: bold;
  padding: 0;
}
.answer-hint {
  padding: 15px;
  color: #2cb774;
  font-size: 1rem;
}
.answer-capital {
  padding: 15px;
  font-size: 1rem;
  color: #fa141a;
}
.answer-title {
  padding: 12px 0 15px 20px;
  color: #2cb774;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
}
.text-right {
  padding-right: 5rem;
  button {
    margin: 2rem;
    padding: 0.4rem 1.6rem;
    font-size: 16px;
  }
  .disabled {
    color: #333 !important;
    font-weight: bold;
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5 !important;
  }
  .round-text {
    background-color: #fdcb41;
    border: none;
    outline: none;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
  }
  .round {
    font-weight: bold;
  }
  .round:not(:disabled):not(.disabled):active,
  .round:not(:disabled):not(.disabled).active,
  .show > .round.dropdown-toggle {
    color: #fff;
    background-color: #2cb774;
    border-color: #2cb774;
  }
  .round-text:not(:disabled):not(.disabled):active,
  .round-text:not(:disabled):not(.disabled).active,
  .show > .round-text.dropdown-toggle {
    color: #fff;
    background-color: #fdcb41;
    border-color: #fdcb41;
  }
}
.submit-group {
  align-self: flex-end;
}
</style>
<style lang="scss">
// .redRadio {
//   .custom-control-input:checked ~ .custom-control-label::before {
//     background-color: #cc0000 !important;
//     border-color: #cc0000 !important;
//   }
// }
.reference-answer {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
