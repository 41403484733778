import { axios } from '@/utils/request'

const api = {
  GetExamSectionsAsync: "/services/app/EnglishCommon/GetExamSectionsAsync",
  GetEngExamQuestionInfos: "/services/app/EnglishCommon/GetEngExamQuestionInfos",
  SaveEngExam: "/services/app/EnglishCommon/SaveEngExam",
  GetEngFinishProgress: "/services/app/EnglishBigModule/GetEngFinishProgress",
  GetEngBigTestReport: "/services/app/EnglishReport/GetEngBigTestReport",
  GetBigModuleTraining: "/services/app/EnglishBigModule/GetBigModuleTraining",
  GetBigModuleQuestions: "/services/app/EnglishBigTraining/GetBigModuleQuestions",
  SaveManyTrainingAnswers: "/services/app/EnglishBigTraining/SaveManyTrainingAnswers",
  GetGroupFinish :'/services/app/EnglishBigModule/GetGroupFinish',
  GetTrainingFinish:'/services/app/EnglishBigTraining/GetTrainingFinish',
  GetBigModuleFreeTraining:'/services/app/EnglishBigModule/GetBigModuleFreeTraining',
  EngPdfTestReport: '/services/app/EnglishReport/EngPdfTestReport'
}

//答题页面接口
export function getExamSectionsAsync(parameter) {
  return axios({
    url: api.GetExamSectionsAsync,
    method: "get",
    params: parameter,
  });
}
//展开页面接口
export function getEngExamQuestionInfos(parameter) {
  return axios({
    url: api.GetEngExamQuestionInfos,
    method: "get",
    params: parameter,
  });
}
// 提交答案接口
export function saveEngExam (parameter) {
  return axios({
    url: api.SaveEngExam,
    method: 'post',
    data: parameter
  })
}
//完成页接口
export function getEngFinishProgress(parameter) {
  return axios({
    url: api.GetEngFinishProgress,
    method: "get",
    params: parameter,
  });
}
//诊断报告接口
export function getEngBigTestReport(parameter) {
  return axios({
    url: api.GetEngBigTestReport,
    method: "get",
    params: parameter,
  });
}
//靶向训练页接口
export function getBigModuleTraining(parameter) {
  return axios({
    url: api.GetBigModuleTraining,
    method: "get",
    params: parameter,
  });
}
//训练题调题接口
export function getBigModuleQuestions (parameter) {
  return axios({
    url: api.GetBigModuleQuestions,
    method: 'post',
    data: parameter
  })
}
//保存训练
export function saveManyTrainingAnswers (parameter) {
  return axios({
    url: api.SaveManyTrainingAnswers,
    method: 'post',
    data: parameter
  })
}
//题组完成页
export function getGroupFinish(parameter) {
  return axios({
    url: api.GetGroupFinish,
    method: "get",
    params: parameter,
  });
}
// 训练完成
export function getTrainingFinish(parameter) {
  return axios({
    url: api.GetTrainingFinish,
    method: "get",
    params: parameter,
  });
}



export function getBigModuleFreeTraining(parameter) {
  return axios({
    url: api.GetBigModuleFreeTraining,
    method: "get",
    params: parameter,
  });
}

// 大模块 pdf下载 
export function engPdfTestReport(parameter) {
  return axios({
    url: api.EngPdfTestReport,
    method: "post",
    params: parameter,
  });
}