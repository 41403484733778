<template>
  <div style="width: 100%; padding: 10px 17px 2rem 17px">
    <el-row :gutter="10" class="log-row" v-if="showLog">
      <span class="answer-record">答题记录：</span>
      <el-col :span="6">
        <el-select
          class="select-record"
          v-model="timer"
          placeholder="请选择"
          @change="change"
        >
          <el-option
            v-for="(item, key) in logDetail.answers"
            :key="item[0].creationTime"
            :label="renderLaber(item[0].isRedo, item[0].isPractice, key)"
            :value="key"
          >
          </el-option>
        </el-select>
      </el-col>
      <span class="tag-type">请先选择左侧答题记录时间</span>
      <!-- 总分得分均为前端数据处理 -->
      <el-col class="score-bar" :span="6" v-show="timer"
        ><span>总分：</span>（<span class="score">{{ score }}</span> /
        <span class="full-score">{{ questionScore }}</span
        >）</el-col
      >
    </el-row>
    <div v-if="![6, 7, 17, 18, 19, 20].includes(section.sectionType)">
      <div v-if="examQuestion.description" class="testinfor-title">
        {{ examQuestion.description }}
      </div>
      <div
        v-if="examQuestion.stemStatement"
        v-html="examQuestion.stemStatement"
        class="stem-statement"
      ></div>
      <EnglishTestQuestion
        ref="questions"
        v-for="(question,
        questionIndex) in examQuestion.simulationExamQuestions"
        :examQuestion="question"
        :setionId="section.sectionId"
        @changeRadio="changeRadio"
        :key="question.questionId"
        :index="index"
        :errAnswer="newErrAnswers[questionIndex]"
        :isSelfScores="isSelfScores"
        :questionIndex="questionIndex"
        :answerCount="answerCount"
        @textareaChange="textareaChange"
        :answer="getOldAnswers(1, question.questionId)"
      >
        <b-col
          slot="literacyImprovement"
          v-if="isAnswerKeyOpen"
          cols="12"
          class="literacy-improvement"
        >
          <b-row
            v-if="$route.query.type != 24"
            align-h="between"
            class="answer-analysis"
          >
            <b-col align-self="start" cols="6" style="color: #2cb774"
              >答题解析及素养提升：</b-col
            >
            <b-col
              @click="isAnswerKeyShow(questionIndex)"
              align-self="start"
              cols="2"
              class="view-all"
            >
              <div v-if="isAnswerKeyClass[questionIndex]">
                <span>查看全部</span>
                <span class="iconfont icon-jiantou8"></span>
              </div>
              <div v-if="!isAnswerKeyClass[questionIndex]">
                <span>收起信息</span>
                <span class="iconfont icon-jiantou_xia"></span>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="analysis-class"
              :class="
                !isAnswerKeyClass[questionIndex] ? 'isAnswerKey-class' : ''
              "
            >
              <b-row style="padding-bottom: 1rem">
                <b-col class="reference-answer" cols="12">
                  正确答案：<span v-html="question.standardAnswerKey"></span>
                </b-col>
                <b-col
                  v-html="replaceAnalysis(question.answerAnalysis)"
                ></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </EnglishTestQuestion>
    </div>
    <div class="chooseWords" v-if="[6, 7,17,18].includes(section.sectionType)">
      <div
        v-if="examQuestion.description"
        class="testinfor-title"
        v-html="examQuestion.description"
      ></div>
      <div
        v-if="examQuestion.stemStatement"
        class="stem-statement"
        v-html="examQuestion.stemStatement"
      ></div>
      <div
        v-for="question in examQuestion.simulationExamQuestions"
        :key="question.questionId"
        class="chooseWordsContent"
      >
        <div
          :class="[question.questionId]"
          ref="questionHtml"
          @input="getInput(index, question.questionId, section.sectionType)"
          v-html="question.statement"
        ></div>
      </div>
    </div>
    <div
      v-if="[19, 20].includes(section.sectionType) || $route.query.type == 24"
    >
      <div v-if="examQuestion.description" class="testinfor-title">
        {{ examQuestion.description }}
        （{{ examQuestion.sectionScore }}）
      </div>
      <div
        v-if="examQuestion.stemStatement"
        class="stem-statement"
        v-html="examQuestion.stemStatement"
      ></div>
      <div
        v-for="(question,
        questionIndex) in examQuestion.simulationExamQuestions"
        :key="question.questionId"
        class="answer-input"
      >
        <b-row cols="1" class="test-questions" style="text-align: left">
          <b-col v-show="JSON.parse($route.query.type) !== 24">
            <span>{{ question.variation + ". " }}</span>
            <!-- 小作文 -->
            <span v-html="question.statement"></span>
          </b-col>
          <b-col
            class="input-box"
            cols="12"
            v-if="examQuestion.plyTkSectionId !== 20"
          >
            <!-- v-model="text" -->
            <b-form-textarea
              v-if="showLog"
              :id="question.questionId"
              :placeholder="
                showLog ? '请选择答题记录查看答案' : '请在此处输入答案'
              "
              v-model="text[questionIndex]"
              rows="5"
              :disabled="!isSelfScores[questionIndex] || showLog"
              @update="
                (value) => {
                  textareaChange(value, index, question.questionId, 0);
                }
              "
            ></b-form-textarea>
            <b-form-textarea
              v-else
              :id="question.questionId"
              :placeholder="
                showLog ? '请选择答题记录查看答案' : '请在此处输入答案'
              "
              rows="5"
              :disabled="!isSelfScores[questionIndex] || showLog"
              @update="
                (value) => {
                  textareaChange(value, index, question.questionId, 0);
                }
              "
            ></b-form-textarea>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? !writeSelfScore
                : !isSelfScores[questionIndex]
            "
          >
            <div class="demonstration-text">
              <div class="text-left">参考答案：</div>
              <div class="text-left" v-html="question.standardAnswerKey"></div>
            </div>
          </b-col>
          <b-col v-if="showLog && examQuestion.plyTkSectionId == 20">
            <el-image
              v-if="answerImg"
              style="width: 200px; height: 100%"
              :src="imgURl + answerImg.toString().replace('image:', '')"
              :preview-src-list="[
                imgURl + answerImg.toString().replace('image:', ''),
              ]"
            >
            </el-image>
          </b-col>
          <b-col v-if="examQuestion.plyTkSectionId === 20 && !showLog">
            <WirteAnswer
              :questionId="question.questionId"
              :sectionId="section.sectionId"
              :studentExamId="userAnswers.studentExamId"
              :isLock="writeSelfScore"
              @afterRefreshImg="afterRefreshImg"
            >
            </WirteAnswer>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? !writeSelfScore
                : !isSelfScores[questionIndex]
            "
            class="self-rating-class"
          >
            <span>自评得分：</span>
            <b-form-input
              type="number"
              class="self-rating"
              min="0"
              :max="question.questionScore"
              @update="
                (value) => {
                  textareaChange(
                    value,
                    index,
                    question.questionId,
                    1,
                    question.questionScore
                  );
                }
              "
              @blur="
                (tg) => {
                  scoreCheck(tg, question.questionScore);
                }
              "
            ></b-form-input>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? writeSelfScore
                : isSelfScores[questionIndex]
            "
            class="self-rating-class"
          >
            <b-button
              :disabled="showLog"
              @click="
                openSelfScore(
                  questionIndex,
                  examQuestion.plyTkSectionId,
                  question.questionId
                )
              "
            >
              {{
                showLog || selfEvaluation
                  ? `自评得分：${dataScore[questionIndex] || totalPrice}`
                  : "自评得分"
              }}</b-button
            >
          </b-col>
          <b-col
            v-if="
              ($route.query.type == 19 && answerCount == 1) ||
                (showLog && $route.query.type !== 24)
            "
            cols="12"
          >
            <b-row align-h="between" class="answer-analysis">
              <b-col align-self="start" cols="6" style="color: #2cb774"
                >答题解析及素养提升：</b-col
              >
              <b-col
                @click="isAnswerKeyShow(questionIndex)"
                align-self="start"
                cols="2"
                class="view-all"
                ><div v-if="isAnswerKeyClass[questionIndex]">
                  <span>查看全部</span>
                  <span class="iconfont icon-jiantou8"></span>
                </div>
                <div v-if="!isAnswerKeyClass[questionIndex]">
                  <span>收起信息</span>
                  <span class="iconfont icon-jiantou_xia"></span>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="analysis-class"
                :class="
                  isAnswerKeyClass[questionIndex] ? '' : 'isAnswerKey-class'
                "
              >
                <b-row>
                  <b-col
                    :class="
                      question.standardAnswerKey.length <= 90
                        ? 'reference-answer'
                        : 'long-answer'
                    "
                    cols="12"
                  >
                    <span>正确答案：</span>
                    <span v-html="question.standardAnswerKey"></span>
                  </b-col>
                  <b-col
                    v-html="replaceAnalysis(question.answerAnalysis)"
                  ></b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Image } from "element-ui";
import EnglishTestQuestion from "@/components/englinsh-test/english-test-question.vue";
import WirteAnswer from "@/views/english-mock/components/wirte-answer.vue";
import { mapState } from "vuex";
import filterHtml, { getText } from "@/utils/processingHTML";
const imgURl = process.env.VUE_APP_API_BASE_URL + "/api/media/getanswerimage/";
export default {
  data() {
    return {
      newErrAnswers: "",
      newInputErrs: "",
      timer: "",
      text: "",
      score: 0,
      isAnswerKeyClass: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      questionScore: 0,
      dataScore: 0,
      imgURl,
      answerImg: "",
    };
  },
  components: {
    EnglishTestQuestion,
    WirteAnswer,
    "el-image": Image,
  },
  created() {
    console.log("examQuestion", this.examQuestion);
  },
  props: {
    isRedo: {
      type: Boolean,
      default: false,
    },
    examQuestion: {
      default: function() {
        return {};
      },
    },
    section: {
      default: function() {
        return {};
      },
    },
    index: {
      default: "",
    },
    answerCount: {
      default: "",
    },
    isSelfScores: {
      default: function() {
        return [];
      },
    },
    writeSelfScore: {
      default: true,
    },
    isAnswerKeyOpen: {
      default: false,
    },
    selfEvaluation: {
      default: false,
    },
    totalPrice: {
      default: "",
    },
    userAnswers: {
      default: function() {
        return {};
      },
    },
    errAnswers: {
      default: function() {
        return [];
      },
    },
    inputErrs: {
      default: function() {
        return [];
      },
    },
  },
  watch: {
    errAnswers: {
      handler(value) {
        this.newErrAnswers = value;
      },
      immediate: true,
    },
    inputErrs: {
      handler(value) {
        this.newInputErrs = value;
        let elList = document.getElementsByClassName("fill-blank");
        for (let i = 0; i < elList.length; i++) {
          if (this.newInputErrs[i]) {
            elList[i].style.backgroundColor = "rgba(127, 255, 170, 0.2)";
          } else {
            elList[i].style.backgroundColor = "rgba(255,100,97,.3)";
          }
        }
      },
    },
  },
  computed: {
    ...mapState(["logDetail", "trainingAnswer", "currentModuleId"]),
    showLog() {
      return this.$route.meta.showLog;
    },
  },
  methods: {
    isAnswerKeyShow(questionIndex) {
      this.$set(
        this.isAnswerKeyClass,
        questionIndex,
        !this.isAnswerKeyClass[questionIndex]
      );
      console.log(
        "this.isAnswerKeyClass",
        this.isAnswerKeyClass[questionIndex]
      );
    },
    change(value) {
      this.text = "";
      const data = this.logDetail.answers[value];
      this.text = this.logDetail.answers[value].map((item) => item.answer);
      this.dataScore = this.logDetail.answers[value].map((item) => item.score);
      this.answerImg = this.logDetail.answers[value].map((item) => item.answer);
  
      // 得分
      this.score = this.logDetail.answers[value]
        .map((item) => item.score)
        .reduce((a, b) => a + b, 0);

      // 总分
      this.questionScore = this.logDetail.answers[value]
        .map((item) => item.fullScore)
        .reduce((a, b) => a + b, 0);
        
      // 前端处理判题逻辑，不支持同一个空多个答案的，例如：Although/Though
      if ([6, 7, 17, 18].includes(this.section.sectionType)) {
        let standardAnswer = getText(
          this.logDetail.questions[0].standardAnswerKey
        ).split("|");
        let arr = data[0].answer.split("|").map((item, i) => ({
          answer: item,
          standardAnswer: standardAnswer[i],
          isCorrect: item.toUpperCase() === standardAnswer[i].toUpperCase(),
        }));
        this.examQuestion.simulationExamQuestions[0].statement = filterHtml(
          JSON.parse(sessionStorage.getItem("logDetail")).questions[0]
            .statement,
          arr
        );
      }
      if (![6, 7, 19, 20].includes(this.section.sectionType)) {
        this.$refs.questions.forEach((item, i) => item.change(value, i));
      }
    },
    changeRadio(checked, questionId) {
      // debugger
      this.$emit("changeRadio", checked, questionId, this.section.sectionId);
    },
    getInput(index, questionId, sectionType) {
      this.$emit("getInput", index, questionId, sectionType);
    },
    textareaChange(value, index, questionId, type, score) {
      this.$emit("textareaChange", value, index, questionId, type, score);
    },
    afterRefreshImg() {
      this.$emit("afterRefreshImg");
    },
    scoreCheck(tg, maxScore) {
      console.log("失去焦点", tg.target.value);
      console.log("maxScore", maxScore);
      if (tg.target.value > maxScore) {
        tg.target.value = maxScore;
      }
      if (tg.target.value < 0) {
        tg.target.value = 0;
      }
    },
    openSelfScore(questionIndex, plyTkSectionId, questionId) {
      this.$emit("openSelfScore", questionIndex, plyTkSectionId, questionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.isAnswerKey-class {
  height: 100% !important;
}
.analysis-class {
  padding-top: 2rem;
  height: 6rem;
  overflow: hidden;
  h3 {
    font-size: 1rem !important;
  }
  .reference-answer {
    padding-bottom: 1rem;
    display: flex;
    p {
      margin-bottom: 0 !important;
    }
  }
  .long-answer {
    p {
      margin-top: 1rem;
    }
  }
}
.tag-type {
  color: #2cb774;
  font-weight: 700;
  background-color: #f4f4f5;
  border: 1px solid #e9e9e9;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 15px;
}
.literacy-improvement {
  padding-bottom: 1.5rem;
}
.icon-jiantou_xia {
  font-size: 12px;
  padding-right: 5px;
}
.stem-statement {
  text-align: left;
  line-height: 2.5;
}
.answer-input {
  padding-left: 15px;
}

.text-left {
  padding: 0.5rem 0;
}
.self-rating-class {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 1rem;
  .self-rating {
    width: 8%;
    text-align: center;
  }
}
.test-questions {
  display: flex;
  & > .col:nth-child(1) {
    display: flex;
    & > span:nth-child(1) {
      padding-right: 0.6rem;
    }
  }
  .input-box {
    padding: 0;
    .form-control {
      width: 96%;
      margin-left: 1rem;
    }
  }
}
.chooseWordsContent {
  text-align: left;
  line-height: 2.5;
  table {
    margin: 0;
    p {
      margin: 0;
    }
  }
}
.log-row {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  margin: 0 !important;
  .answer-record {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.view-all {
  color: #2cb774;
  cursor: pointer;
}
.answer-analysis {
  min-height: 9rem;
  text-align: left;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid rgb(240, 240, 240);
  margin-bottom: 1rem;
}
</style>
<style lang="scss">
.redRadioNull {
  .fill-blank {
    background-color: rgba(255, 100, 97, 0.3);
  }
}
.chooseWordsContent {
  table {
    margin: 0 auto;
    td {
      vertical-align: middle;
      p {
        margin: 0;
      }
    }
  }
}
.stem-statement {
  table {
    tbody {
      td {
        vertical-align: middle;
      }
      p {
        margin: 0;
      }
    }
  }
}
.score-bar {
  margin-left: 2rem;
  text-align: left;
  color: $text-light-gray;

  span {
    color: $text-green;
    font-size: 1rem;
    font-weight: bold;
  }
  .score {
  }
  .full-score {
    color: $text-light-gray;
  }
}
.long-answer {
  p {
    margin-top: 1rem;
  }
}
</style>
